import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';

const BackgroundAnimation = () => {
  return (
    <ul className="background">
      {[...Array(10)].map((_, index) => (
        <li key={index}></li>
      ))}
    </ul>
  );
};

const TopBar = () => {
  return (
    <>
      <div className="header-tagline-bar">
        <Container className="text-center">
          <div className="tagline">Innovative, Artful Solutions</div>
        </Container>
      </div>
      <div className="header-top-bar">
        <Container className="text-center">
          <div className="header-contact-wrapper">
            <div className="header-contact-group">
              <div className="header-contact-country">
                <a href="tel:+94770180044">+94 (770) 180-044</a>
                <span className="separator">|</span>
                <a href="tel:+94754499993.">+94 (754) 499-993</a>
                <span className="separator">|</span>
                <a href="mailto:lk.hello@spirelab.net">lk.hello@spirelab.net</a>
                <span className="separator">|</span>
                <span>Colombo, Sri Lanka</span>
              </div>
              
              <div className="header-contact-country">
                <a href="tel:+44738187649">+44 (738) 187-649</a>
                <span className="separator">|</span>
                <a href="mailto:uk.hello@spirelab.net">uk.hello@spirelab.net</a>
                <span className="separator">|</span>
                <span>London, United Kingdom</span>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem('token');

  if (location.pathname.startsWith('/view/')) {
    return null;
  }

  const handleAdminClick = (e) => {
    if (!isAdmin) {
      e.preventDefault();
      navigate('/login');
    }
  };

  return (
    <header className="site-header">
      <TopBar />
      <BackgroundAnimation />
      <Navbar expand="lg" className="header-navbar py-3" bg="dark" variant="dark">
        <Container className="text-center">
          <Navbar.Brand as={Link} to="/" className="header-brand">Demo Showcase</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link 
                as={Link} 
                to="/" 
                className="header-nav-link"
                active={location.pathname === '/'}
              >
                Home
              </Nav.Link>
              {isAdmin ? (
                <Nav.Link 
                  as={Link} 
                  to="/admin" 
                  className="header-nav-link"
                  active={location.pathname === '/admin'}
                >
                  Admin
                </Nav.Link>
              ) : (
                <Nav.Link 
                  as={Link} 
                  to="/admin" 
                  className="header-nav-link"
                  onClick={handleAdminClick}
                >
                  Admin
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
