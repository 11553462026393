import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Alert, Nav, Badge, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const BACKGROUND_ITEMS = [
  { text: 'E-commerce' },
  { text: 'Healthcare' },
  { text: 'Finance' },
  { text: 'Education' },
  { text: 'Manufacturing' },
  { text: 'Technology' },
  { text: 'Real Estate' },
  { text: 'Automotive' },
  { text: 'Entertainment' },
  { text: 'Travel' },
  { text: 'Food & Beverage' },
  { text: 'Sports' },
  { text: 'Fashion' },
  { text: 'Media' },
  { text: 'Agriculture' },
  { text: 'Energy' },
  { text: 'Logistics' },
  { text: 'Retail' },
  { text: 'Construction' },
  { text: 'Telecommunications' }
];

const Showcase = () => {
  const [sites, setSites] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [activeCategory, setActiveCategory] = useState('All');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSites = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sites`);
        console.log('Sites data:', response.data);
        setSites(response.data);
      } catch (error) {
        setError(error.response?.data?.error || 'Failed to load demo sites');
      } finally {
        setLoading(false);
      }
    };

    fetchSites();
  }, []);

  // Get unique categories from the database and sort them, but keep "All" first
  const categories = ['All'];
  const dbCategories = [...new Set(sites.map(site => site.category || 'General'))]
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b));
  categories.push(...dbCategories);
  
  console.log('Active Category:', activeCategory);
  console.log('All Sites:', sites);
  
  // Get all featured sites (unfiltered)
  const featuredSites = sites.filter(site => site.featured);
  
  // Filter non-featured sites based on category
  const nonFeaturedSites = sites
    .filter(site => !site.featured)
    .filter(site => {
      if (activeCategory === 'All') return true;
      const siteCategory = site.category || 'General';
      return siteCategory === activeCategory;
    });

  const handleSiteClick = (siteId) => {
    const site = sites.find(s => s.id === siteId);
    if (site) {
      navigate(`/view/${siteId}`, { 
        state: { 
          url: site.url,
          name: site.name
        } 
      });
    }
  };

  if (loading) {
    return (
      <Container className="py-5 text-center">
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </Container>
    );
  }

  return (
    <Container className="py-4 showcase-container">
      <h1 className="text-center mb-4 showcase-title" style={{ 
        color: 'rgba(255, 255, 255, 0.9)',
        fontWeight: 'bold',
        fontSize: '2.5rem',
        letterSpacing: '-0.5px'
      }}>
        Demo Showcase
      </h1>

      {error && (
        <Alert variant="danger" className="mb-4" dismissible onClose={() => setError('')}>
          {error}
        </Alert>
      )}

      {/* Featured Section */}
      <section className="featured-section mb-5">
        <h2 className="section-title mb-4">Featured Sites</h2>
        <Row xs={1} md={2} lg={3} className="g-4">
          {featuredSites.map((site) => (
            <Col key={site.id}>
              <Card 
                className={`h-100 demo-thumb featured`}
              >
                <div className="ratio ratio-16x9">
                  <iframe
                    src={site.url}
                    title={site.name}
                    loading="lazy"
                    referrerPolicy="no-referrer"
                    sandbox="allow-same-origin allow-scripts allow-forms"
                    style={{ pointerEvents: 'none' }}
                    onError={(e) => {
                      console.error('Iframe loading error:', e);
                      e.target.style.display = 'none';
                    }}
                  />
                </div>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-start mb-2">
                    <Card.Title className="text-light mb-0">{site.name}</Card.Title>
                    <Badge 
                      bg="primary"
                      style={{
                        background: 'var(--accent-gradient)',
                        opacity: 0.9
                      }}
                    >
                      Featured
                    </Badge>
                  </div>
                  <Card.Text className="text-light opacity-75">{site.description}</Card.Text>
                  <Badge 
                    bg="secondary" 
                    className="mt-2"
                    style={{ opacity: 0.7 }}
                  >
                    {site.category || 'General'}
                  </Badge>
                </Card.Body>
                <Card.Footer className="d-flex justify-content-between">
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleSiteClick(site.id)}
                  >
                    View
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </section>

      <h2 className="section-title mb-4">All Sites</h2>
      <Nav className="category-tabs justify-content-center mb-4">
        {categories.map((category) => (
          <Nav.Link
            key={category}
            active={activeCategory === category}
            onClick={() => setActiveCategory(category)}
            className={`mx-2 ${activeCategory === category ? 'active' : ''}`}
          >
            {category}
          </Nav.Link>
        ))}
      </Nav>

      <Row xs={1} md={2} lg={3} className="g-4">
        {nonFeaturedSites.map((site) => (
          <Col key={site.id}>
            <Card 
              className={`h-100 demo-thumb ${site.featured ? 'featured' : ''}`}
            >
              <div className="ratio ratio-16x9">
                <iframe
                  src={site.url}
                  title={site.name}
                  loading="lazy"
                  referrerPolicy="no-referrer"
                  sandbox="allow-same-origin allow-scripts allow-forms"
                  style={{ pointerEvents: 'none' }}
                  onError={(e) => {
                    console.error('Iframe loading error:', e);
                    e.target.style.display = 'none';
                  }}
                />
              </div>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-start mb-2">
                  <Card.Title className="text-light mb-0">{site.name}</Card.Title>
                  {site.featured && (
                    <Badge 
                      bg="primary"
                      style={{
                        background: 'var(--accent-gradient)',
                        opacity: 0.9
                      }}
                    >
                      Featured
                    </Badge>
                  )}
                </div>
                <Card.Text className="text-light opacity-75">{site.description}</Card.Text>
                <Badge 
                  bg="secondary" 
                  className="mt-2"
                  style={{ opacity: 0.7 }}
                >
                  {site.category || 'General'}
                </Badge>
              </Card.Body>
              <Card.Footer className="d-flex justify-content-between">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => handleSiteClick(site.id)}
                >
                  View
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Showcase;
