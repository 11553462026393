import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Alert, Container } from 'react-bootstrap';
import axios from 'axios';

const DemoView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [site, setSite] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSite = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sites/${id}`);
        setSite(response.data);
      } catch (error) {
        console.error('Error fetching site:', error);
        setError('Failed to load the demo site. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchSite();
  }, [id]);

  if (loading) {
    return (
      <Container className="py-5 text-center">
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger">{error}</Alert>
        <Button variant="primary" onClick={() => navigate('/')}>
          ← Back to Showcase
        </Button>
      </Container>
    );
  }

  if (!site) {
    return (
      <Container className="py-5">
        <Alert variant="warning">Site not found</Alert>
        <Button variant="primary" onClick={() => navigate('/')}>
          ← Back to Showcase
        </Button>
      </Container>
    );
  }

  return (
    <div className="demo-frame-container">
      <Button
        className="back-button"
        onClick={() => navigate('/')}
      >
        ← Back to Showcase
      </Button>
      <iframe
        src={site.url}
        title={site.name}
        className="demo-frame"
        sandbox="allow-same-origin allow-scripts allow-forms"
        referrerPolicy="no-referrer"
        loading="lazy"
        onError={(e) => {
          console.error('Iframe loading error:', e);
          setError('Failed to load the demo site content.');
        }}
      />
    </div>
  );
};

export default DemoView;
