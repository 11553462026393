import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';

const SiteView = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { url, name } = location.state || {};

  if (!url) {
    navigate('/');
    return null;
  }

  return (
    <Container fluid className="p-0 site-view-container">
      <div className="site-view-header">
        <Button 
          variant="outline-light" 
          onClick={() => navigate('/')}
          className="back-button"
        >
          Back to Showcase
        </Button>
        <h4 className="site-title">{name}</h4>
      </div>
      <div className="site-frame-container">
        <iframe
          src={url}
          title={name}
          className="site-frame"
          sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
        />
      </div>
    </Container>
  );
};

export default SiteView;
