import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="site-footer">
      <Container>
        <Row className="footer-main py-5">
          <Col md={3}>
            <h5>Spirelab</h5>
            <div className="footer-block">
              <p className="mt-3">
                We work with a passion of taking challenges and creating new ones in advertising sector
              </p>
              <p className="company-name">Spirelab Solutions</p>
            </div>
          </Col>
          
          <Col md={6}>
            <h5 className="mb-4">Our Offices</h5>
            <Row>
              <Col md={6} className="mb-4 mb-md-0">
                <div className="footer-block">
                  <h6 className="office-title">UK Office</h6>
                  <p>
                    23 New Drum Street<br />
                    London<br />
                    United Kingdom<br />
                    E1 7AY<br />
                    +44 (738) 0187-649
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="footer-block">
                  <h6 className="office-title">SL Office</h6>
                  <p>
                    316 2nd Floor<br />
                    Super Market Complex<br />
                    Mount Lavinia<br />
                    Sri Lanka<br />
                    +94 (770) 180-044
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          
          <Col md={3}>
            <h5>Our sites</h5>
            <div className="footer-block">
              <ul className="list-unstyled footer-links">
                <li>
                  <a href="https://www.spirelab.net" target="_blank" rel="noopener noreferrer">
                    Spirelab Solutions
                  </a>
                </li>
                <li>
                  <a href="https://www.splb.dev" target="_blank" rel="noopener noreferrer">
                    Splb AI
                  </a>
                </li>
                <li>
                  <a href="https://www.clonebs.com" target="_blank" rel="noopener noreferrer">
                    Clone Business Solutions
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        
        <Row className="copyright-row">
          <Col className="text-center py-3 border-top">
            2024 Spirelab Solutions Ltd. All rights reserved.
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
